//==============================================================================
// Analytics Event Dispatcher (Simple Pub-Sub system)
//
// This module receives events and forwards them to the appropriate handlers
// Subscriptions are managed using a subscriber ID to aid in unsubscribing
//
// NOTE: Singletons aren't compatible with SSR. This module acts as a singleton,
// maintaining its data as a global. Modules MUST call subscribe on the client
// ONLY (via componentDidMount).
//
// @TODO: Currently, only client-side events will be processed. Events generated
// during server-side renders will be lost. If we move data to a data action
// it can be persisted from server to client. That will allow us to queue up
// events generated on the server or before subscriptions occur, and emit
// them immediately upon subscription.
//==============================================================================
import { EventHandler } from "./analytics-events";

//==============================================================================
// INTERFACES
//==============================================================================

// List of subscribers to a single event
interface EventSubscribers {
    [subscriber: string]: EventHandler;
}

//==============================================================================
// GLOBALS
//==============================================================================

// List of subscriptions, organized by event name and then subscriber name
const subscriptions: { [eventName: string]: EventSubscribers } = {};

//==============================================================================
// FUNCTIONS
//==============================================================================

//==========================================================
// Called by event emitters when an event occurs
//
// NOTE: This should only be called on the client! Events on
// the server will be lost. They should instead be queued
// via a data action cache.
//==========================================================
export function publish(eventName: string, eventData: unknown): void {

    // Get the list of subscribers for this event
    const subscribers = Object.keys(subscriptions[eventName] || {});

    // Notify each subscriber
    subscribers.forEach(subscriber => subscriptions[eventName][subscriber](eventData));
}

//==========================================================
// Called by event handlers when they self-register for events
//==========================================================
export function subscribe(subscriber: string, eventName: string, handler: EventHandler): void {

    // If it's a new event, create an entry
    subscriptions[eventName] = subscriptions[eventName] || {};

    // Ensure this subscriber isn't already subscribed to this event
    if (subscriptions[eventName][subscriber]) {
        throw new Error(`Duplicate subscription request for ${subscriber}: ${eventName}`);
    }

    // Add the subscription
    subscriptions[eventName][subscriber] = handler;
}

//==========================================================
// Unsubscribe from a single event
//==========================================================
export function unsubscribe(subscriber: string, eventName: string): void {
    if (subscriptions[eventName] && subscriptions[eventName][subscriber]) {
        delete subscriptions[eventName][subscriber];
    }
}
